import React from 'react'
import { useIntl } from 'gatsby-plugin-intl'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { AgencyDetails } from '../component/page_component/AgencyDetails'
import { Banner } from '../component/Banner'
import { Destination } from '../component/page_component/Destination'
import { Display } from '../component/page_component/Display'
import { Estimate } from '../component/page_component/Estimate'
import { FriendsOfHouse } from '../component/page_component/FriendsOfHouse'
import { Intro } from '../component/page_component/Intro'
import { Layout } from '../component/Layout'
import { LifeStyle } from '../component/page_component/LifeStyle'
import { Network } from '../component/page_component/Network'
import { Technology } from '../component/page_component/Technology'
import { graphql, PageProps, useStaticQuery } from 'gatsby'
import clsx from 'clsx'
import poster from '../../src/images/preview.png'
import videoMp4 from '../../src/images/video-hp.mp4'
import hreflangConfig from '../utils/hreflangConfig'
import { Helmet } from 'react-helmet'

const useStyles = makeStyles((theme) => ({
  intro: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: theme.spacing(20),
    paddingBottom: theme.spacing(12),
    [theme.breakpoints.between('xs', 'sm')]: {
      paddingTop: theme.spacing(0),
      paddingBottom: theme.spacing(0),
    },
  },
  container: {
    textAlign: 'center',
  },
  orange: {
    backgroundColor: '#FAE9E5',
  },
}))

const HOME_PAGE_QUERY = graphql`
  query HomePageQuery {
    site {
      siteMetadata {
        siteUrl
      }
    }
    backoffice {
      publicPropertyList(
        isProtected: false
        isOffmarket: false
        limit: 10
        ordering: "-prix_affichage_fai"
        favoris: true
      ) {
        edges {
          node {
            id
            pk
            createdAt
            ville
            villeFr
            villeEn
            villePt
            villeEs
            paysFr
            paysEn
            paysEs
            paysPt
            departementFr
            departementEn
            departementEs
            departementPt
            regionFr
            regionEn
            regionEs
            regionPt
            autreVille
            reference
            propertyType
            nombreChambres
            propertyTypeDisplay
            propertyTypeSlug
            nombrePieces
            prixAffichageFai
            prixLoyer
            prixConfidentiel
            photosKp
            surfaceHabitable
            photosKp
            infos
            titreAr
            titreEn
            titreEs
            titreFr
            titrePt
            titreRu
            titreZh
            unitPrice
            unitArea
          }
        }
      }
    }
  }
`

export const Home: React.FC<PageProps> = (props) => {
  const { location } = props
  const intl = useIntl()
  const classes = useStyles()
  const {
    site: {
      siteMetadata: { siteUrl },
    },
    backoffice: {
      publicPropertyList: { edges },
    },
  } = useStaticQuery(HOME_PAGE_QUERY)
  const hreflangLinks = hreflangConfig['/fr/']

  const canonicalUrl = `${siteUrl}${location.pathname}`

  return (
    <Layout location={location} maxWidth={false} title={intl.formatMessage({ id: 'Kretz' })}>
      <Helmet>
        <link rel="canonical" href={canonicalUrl} />
        {hreflangLinks &&
          Object.keys(hreflangLinks).map((lang) => (
            <link key={lang} rel="alternate" hrefLang={lang} href={hreflangLinks[lang]} />
          ))}
      </Helmet>
      <Banner
        videoSrc={{
          mp4: videoMp4,
          poster,
        }}
        innerStyle={{
          zIndex: '1100',
          position: 'relative',
        }}
      ></Banner>
      <div className={classes.intro}>
        <Intro
          title={intl.formatMessage({ id: 'mainScreen.second.title' })}
          description={intl.formatMessage({ id: 'mainScreen.second.details' })}
        />
      </div>
      <Display title={intl.formatMessage({ id: 'display' })} edges={edges} />
      <Network />
      <Destination />
      <LifeStyle />
      <div id={'estimate-section'}>
        <Estimate />
      </div>
      <Technology />
      <AgencyDetails />
      <Grid container className={clsx(classes.container)}>
        <FriendsOfHouse showBtn />
      </Grid>
    </Layout>
  )
}

export default Home
